// import node module libraries
import React from "react";
import { Col, Row, Container, Image } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

// import media files
import PurchaseConfirmedImage from "assets/images/background/comingsoon.svg";

const PurchaseConfirmed = () => {
	const navigate = useNavigate();

	setTimeout(() => {
		navigate("/account");
	}, 3000);

	return (
		<Container className="d-flex flex-column">
			<Row className="align-items-center justify-content-center g-0 py-lg-22 py-10">
				{/* Docs */}
				<Col
					xl={{ span: 5, offset: 1 }}
					lg={6}
					md={12}
					sm={12}
					className="text-center text-lg-start"
				>
					<h1 className="display-3 mb-2 fw-bold">
						Thanks for your purchase!
					</h1>
					<p className="mb-4 fs-4">
						You will be redirected to the dashboard in a few
						seconds...
					</p>
				</Col>
				{/* img */}
				<Col
					xl={{ span: 5, offset: 1 }}
					lg={6}
					md={12}
					sm={12}
					className="mt-8 mt-lg-0"
				>
					<Image
						src={PurchaseConfirmedImage}
						alt=""
						className="w-100"
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default PurchaseConfirmed;
