import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { Container, Row, Col, Table, Badge, Image } from "react-bootstrap";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";

const Filing = () => {
	const { filingId } = useParams();
	const [filing, setFiling] = useState({});
	const [company, setCompany] = useState({});

	useEffect(() => {
		const getFilingAndCompany = async () => {
			try {
				const filingRef = doc(db, "filings", filingId);
				const filingDoc = await getDoc(filingRef);
				setFiling(filingDoc.data());

				const companyRef = filingDoc.data().companyRef;
				const companyDoc = await getDoc(companyRef);
				setCompany(companyDoc.data());

				console.log(JSON.stringify(filingDoc.data()));
				console.log(JSON.stringify(companyDoc.data()));
			} catch (err) {
				console.log(err);
			}
		};

		getFilingAndCompany();
	}, [filingId]);

	const getBgClass = (type) => {
		if (type === "10-K" || type === "10-K/A") {
			return "bg-warning";
		} else if (type === "10-Q" || type === "10-Q/A") {
			return "bg-success";
		} else {
			return "bg-danger";
		}
	};

	return (
		<Container>
			<Row className="mt-6 mb-3">
				<Col md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
					<h1 className="d-flex align-items-center">
						<span
							className="avatar avatar-primary me-0 mb-1 mb-lg-0"
							style={{
								minWidth: "160px",
								minHeight: "160px",
							}}
						>
							<span
								className={`avatar-initials ${getBgClass(filing.type)} rounded h1`}
							>
								{filing.type}
							</span>
						</span>
						<Link to={`/companies/${company.cik}`} className="ms-3">
							{company.name}
						</Link>
					</h1>
					<div>
						<div className="mb-3">
							{/* badge date */}
							<Badge pill bg="light" text="dark" className="me-1">
								{filing.updatedAt?.toDate().toLocaleString()}
							</Badge>
							<br />
							<b>Accession Number:</b> {filing.accessionNumber}
							<br />
							<h2 className="mt-3">{filing.headline}</h2>
						</div>
						<hr />
						<div className="mb-3">
							<Markdown>{filing.summary}</Markdown>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={{ span: 8, offset: 2 }} lg={{ span: 8, offset: 2 }}>
					<h4 className="mb-3">Details</h4>
					<Table responsive>
						<thead>
							<tr>
								<th>Type</th>
								<th>Accession Number</th>
								<th>Sentiment</th>
								<th>Impact</th>
								<th>Weight</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{filing.type}</td>
								<td>{filing.accessionNumber}</td>
								<td>{filing.sentiment}</td>
								<td>{filing.impact}</td>
								<td>{filing.weight}</td>
							</tr>
						</tbody>
					</Table>
				</Col>
			</Row>
		</Container>
	);
};

export default Filing;
