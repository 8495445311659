import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";

const ProtectedRoute = ({ children, ...rest }) => {
	const { user, loading } = useUserAuth();

	// If the user data is still loading, return null or a loading spinner
	if (loading) {
		return null; // or return a loading spinner
	}

	// If there's no user, navigate to the signup page
	if (!user) {
		return <Navigate to="/signup" state={{ from: rest.path }} />;
	}

	// If there's a user, render the children
	return children;
};

export default ProtectedRoute;