import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/scss/theme.scss";

import App from "./App";

const root = document.getElementById("root");

if (!root) {
	throw new Error("No root element found");
}

ReactDOM.createRoot(root).render(
	<React.StrictMode>
		<Router>
			<App />
		</Router>
	</React.StrictMode>
);
