// Navigation.js
import React from "react";
import { Button, Image, Navbar, Nav } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useUserAuth } from "../../context/UserAuthContext";
import NumeriLogo from "../../assets/images/brand/numeri-logo-only-2.png";

const Navigation = () => {
	const { user, logOut } = useUserAuth();
	const navigate = useNavigate();

	const handleLogout = async () => {
		try {
			await logOut();
			navigate("/");
		} catch (error) {
			console.log(error.message);
		}
	};

	return (
		<Navbar collapseOnSelect expand="lg">
			<Navbar.Brand as={Link} to="/" className="h1">
				numeri{" "}
				<Image
					src={NumeriLogo}
					alt="Numeri Logo"
					style={{ height: 40 }}
				/>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="me-auto">
					{/* <Nav.Link as={Link} to="/search">
						Search
					</Nav.Link>
					<Nav.Link as={Link} to="/notifications">
						Notifications
					</Nav.Link> */}
				</Nav>
				<Nav>
					{!user && (
						<>
							<Button
								as={Link}
								variant="outline-primary"
								to="/beta"
								size="sm"
								style={{ borderRadius: 0 }}
							>
								Get Notified
								{/* bell icon */}
								<i className="fe fe-bell ms-2 mt-2"></i>
							</Button>
						</>
					)}
					{user && (
						<>
							<Nav.Link as={Link} to="/account">
								Account
							</Nav.Link>
							<Nav.Link as={Link} onClick={handleLogout}>
								Logout
							</Nav.Link>
						</>
					)}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default Navigation;
