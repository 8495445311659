import React, { useState, useEffect, useMemo } from "react";
import { ListGroup, Col, Container, Row } from "react-bootstrap";
import { db } from "../../firebase";

import {
	collection,
	query,
	orderBy,
	limit,
	onSnapshot,
	getDoc,
} from "firebase/firestore";

import FilingListItem from "./partials/FilingListItem";

const Main = () => {
	const [filings, setFilings] = useState([]);
	const today = useMemo(() => {
		const date = new Date();
		date.setHours(0, 0, 0, 0);
		return date;
	}, []);

	const yesterday = useMemo(() => {
		const date = new Date();
		date.setDate(date.getDate() - 1);
		date.setHours(0, 0, 0, 0);
		return date;
	}, []);

	useEffect(() => {
		const filingsCollection = collection(db, "filings_metadata");

		const q = query(
			filingsCollection,
			orderBy("updatedAt", "desc"), // Order by updatedAt field in descending order
			limit(40) // Only get the last 20 filings
		);

		const unsubscribe = onSnapshot(q, async (snapshot) => {
			const filingsData = await Promise.all(
				snapshot.docs.map(async (filing) => {
					const filingData = {
						id: filing.id,
						...filing.data(),
					};

					// Fetch company data
					const companyRef = filingData.companyRef;
					if (companyRef) {
						const companySnapshot = await getDoc(companyRef);
						if (companySnapshot.exists()) {
							filingData.company = companySnapshot.data();
						}
					}

					return filingData;
				})
			);

			setFilings(filingsData);
		});

		// Clean up the subscription on unmount
		return () => unsubscribe();
	}, []);

	let lastDate = null;

	return (
		<Container className="mt-2" fluid>
			<Row>
				<Col md={{ span: 5, offset: 2 }} lg={{ span: 5, offset: 2 }}>
					<div className="mb-2 mb-xl-0 text-center text-md-start px-1">
						<h1 className="display-3 fw-bold mb-2 ls-sm">
							Live SEC Filings
						</h1>
						{/* zero top and bottom margin for hr */}
						<hr
							style={{
								borderTop: "6px solid #1e293b",
								marginTop: 0,
								marginLeft: 3,
							}}
						/>
						<p className="mb-5">
							Live SEC filings from the top 2000 public companies
						</p>
					</div>
				</Col>
			</Row>

			{filings.map((filing) => {
				const updatedAtDate = filing.updatedAt?.toDate();
				updatedAtDate.setHours(0, 0, 0, 0);
				const updatedAtDateString = updatedAtDate.toLocaleDateString(
					"en-US",
					{
						year: "numeric",
						month: "numeric",
						day: "numeric",
					}
				);

				const showDate = updatedAtDateString !== lastDate;
				lastDate = updatedAtDateString;

				return (
					<Row key={filing.id}>
						<Col className="text-end" md={2} lg={2} xl={2}>
							{showDate && (
								<h5 className="mt-2">{updatedAtDateString}</h5>
							)}
						</Col>
						<Col md={10} lg={10} xl={10}>
							<ListGroup>
								<FilingListItem
									key={filing.id}
									filing={filing}
									updatedAtDate={updatedAtDate}
									today={today}
									yesterday={yesterday}
								/>
							</ListGroup>
						</Col>
						<Col></Col>
					</Row>
				);
			})}
		</Container>
	);
};

export default Main;
