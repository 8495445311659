import { Routes, Route } from "react-router-dom";

// import Account from "./components/views/Account";
// import Login from "./components/views/Login";
// import Signup from "./components/views/Signup";
import Main from "./components/views/Main";
// import Pricing from "./components/views/Pricing";
import BetaSignUp from "./components/views/BetaSignUp";
import Calendar from "./components/views/Calendar";
import Company from "./components/views/Company";
import Filing from "./components/views/Filing";
import Subscribe from "./components/views/Subscribe";
import Search from "./components/views/Search";

import PurchaseConfirmed from "./components/views/PurchaseConfirmed";
import Navigation from "./components/navs/Navigation";
import ProtectedRoute from "./components/modules/ProtectedRoute";

import { UserAuthContextProvider } from "./context/UserAuthContext";

function App() {
	return (
		<UserAuthContextProvider>
			<Navigation />
			<Routes>
				{/* Public routes */}
				{/* <Route path="/login" element={<Login />} /> */}
				{/* <Route path="/signup" element={<Signup />} />
				<Route path="/pricing" element={<Pricing />} /> */}
				<Route path="/beta" element={<BetaSignUp />} />
				<Route path="/companies/:companyId" element={<Company />} />
				<Route path="/calendar" element={<Calendar />} />
				<Route path="/subscribe" element={<Subscribe />} />

				<Route path="/filings/:filingId" element={<Filing />} />

				<Route
					path="/purchase-confirmed"
					element={<PurchaseConfirmed />}
				/>
				{/* Protected routes */}
				{/* <Route
					path="/account/:tab?"
					element={
						<ProtectedRoute>
							<Account />
						</ProtectedRoute>
					}
				/> */}

				<Route
					path="/search"
					element={
						<ProtectedRoute>
							<Search />
						</ProtectedRoute>
					}
				/>

				<Route path="/" element={<Main />} />
			</Routes>
		</UserAuthContextProvider>
	);
}

export default App;
