import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { Container } from "react-bootstrap";

const events = [{ title: "Meeting", start: new Date() }];

const Calendar = () => {
	return (
		<Container>
			<FullCalendar
				plugins={[dayGridPlugin]}
				initialView="dayGridMonth"
				weekends={false}
				events={events}
				eventContent={renderEventContent}
				contentHeight="84vh" // Set the content height to 90% of the viewport height
			/>
		</Container>
	);
};

// a custom render function
function renderEventContent(eventInfo) {
	return (
		<>
			<b>{eventInfo.timeText}</b>
			<i>{eventInfo.event.title}</i>
		</>
	);
}

export default Calendar;
