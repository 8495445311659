// Filing.js
import React from "react";
import { ListGroup } from "react-bootstrap";
import TimeAgo from "react-timeago";
import { CSSTransition } from "react-transition-group";
import englishStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { Link } from "react-router-dom";

const formatter = buildFormatter({
	...englishStrings,
	second: "just now",
	seconds: "%ds",
});

const getBgClass = (type) => {
	if (type === "10-K" || type === "10-K/A") {
		return "bg-warning";
	} else if (type === "10-Q" || type === "10-Q/A") {
		return "bg-success";
	} else {
		return "bg-danger";
	}
};

const FilingListItem = ({ filing, updatedAtDate, today, yesterday }) => {
	// Only render the filing if it was created today or yesterday
	return (
		<CSSTransition
			key={filing.id}
			timeout={1000}
			classNames="ListGroupItem"
		>
			<ListGroup.Item className="border-0 p-1" key={filing.id}>
				<div className="d-flex">
					<div
						className={`avatar avatar-initials text-white ${getBgClass(filing.type)} h5`}
						style={{
							maxWidth: "64px",
							height: "64px",
						}}
					>
						{filing.type}
					</div>
					<Link to={`/filings/${filing.id}`} className="ms-3">
						<h5 className="lh-1">
							<span style={{ borderBottom: "1px solid black" }}>
								{filing.company?.name} ({filing.company?.symbol}
								)
							</span>
						</h5>
						{/* headline summary - truncate to first 140 characters */}
						<small className="mt-1">
							{filing.headline || "Retrieving headline..."} <br />
							<span className="text-muted">
								<TimeAgo
									date={filing.updatedAt?.toDate()}
									formatter={formatter}
									minPeriod={1} // Update every second
								/>
							</span>
						</small>
					</Link>
				</div>
			</ListGroup.Item>
		</CSSTransition>
	);
};

export default FilingListItem;
