import React, { useState, useEffect, useMemo } from "react";
import { Container, Form, Row, ListGroup } from "react-bootstrap";
import { db } from "../../firebase";
import { collection, query, onSnapshot } from "firebase/firestore";

const Subscribe = () => {
	// query the sectors collection in firestore
	// each document in the sectors collection is a sector with the sector name as the document id
	// each sector document contains a list of companies in that sector in the companies field
	// companies looks like this:
	// {
	//     "companies": {
	//         "{cik}": {
	//             "name": "Apple Inc.",
	//             "industry": "Consumer Electronics",
	//             "ticker": "AAPL"
	//         }
	//     }
	// }

	// get the sectors from firestore
	const [sectors, setSectors] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [selectedCompanies, setSelectedCompanies] = useState({});

	const handleCompanyChange = (cik, checked) => {
		setSelectedCompanies((prev) => ({
			...prev,
			[cik]: checked,
		}));
	};

	const handleSelectAllChange = (checked) => {
		setSelectedCompanies(
			checked
				? Object.fromEntries(companies.map(([cik]) => [cik, true]))
				: {}
		);
	};

	const handleChange = (sectorId) => {
		const sector = sectors.find((sector) => sector.id === sectorId);
		setCompanies(Object.entries(sector.companies));
	};

	useEffect(() => {
		const q = query(collection(db, "sectors"));
		const unsubscribe = onSnapshot(q, (snapshot) => {
			const sectors = [];
			snapshot.forEach((doc) => {
				sectors.push({ ...doc.data(), id: String(doc.id) });
			});
			setSectors(sectors);
		});

		return () => {
			unsubscribe();
		};
	}, []);

	const [searchTerm, setSearchTerm] = useState("");

	const filteredCompanies = useMemo(() => {
		if (!searchTerm) {
			return companies;
		}

		return companies.filter(
			([cik, company]) =>
				company.name &&
				company.name.toLowerCase().includes(searchTerm.toLowerCase())
		);
	}, [companies, searchTerm]);

	const allSelected = useMemo(() => {
		return companies.every(([cik]) => selectedCompanies[cik]);
	}, [companies, selectedCompanies]);

	return (
		<Container>
			<Row>
				<h1>Subscribe</h1>
				<p>Subscribe to get access to all the features of this app.</p>
				<Form>
					<Form.Select
						aria-label="Choose a sector"
						onChange={(e) => handleChange(e.target.value)}
					>
						<option>Choose a sector</option>
						{sectors.map((sector) => (
							<option key={sector.id} value={sector.id}>
								{sector.id}
							</option>
						))}
					</Form.Select>
					<Form.Control
						type="text"
						placeholder="Search companies"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</Form>
			</Row>
			<Row>
				{/* ... existing code ... */}
				<Form.Check
					type="checkbox"
					id="select-all"
					label="Select All"
					checked={allSelected}
					onChange={(e) => handleSelectAllChange(e.target.checked)}
				/>
				{filteredCompanies.map(([cik, company]) => (
					<div key={cik}>
						<ListGroup variant="flush">
							<ListGroup.Item>
								<Form.Check
									type="checkbox"
									id={`checkbox-${cik}`}
									label={`${company.name} - ${company.industry}`}
									checked={!!selectedCompanies[cik]}
									onChange={(e) =>
										handleCompanyChange(
											cik,
											e.target.checked
										)
									}
								/>
							</ListGroup.Item>
						</ListGroup>
					</div>
				))}
			</Row>
		</Container>
	);
};

export default Subscribe;
