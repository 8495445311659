import React, { useState } from "react";
import {
	Form,
	Alert,
	Button,
	Container,
	Row,
	Col,
	ListGroup,
} from "react-bootstrap";
import { db } from "../../firebase";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";

const BetaSignUp = () => {
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(""); // New state variable for success message

	// submit the form and add the email to the beta collection in firestore
	// the id of the document will be the email and each document will have a single field called createdAt with the current date
	const handleSubmit = async (e) => {
		e.preventDefault();
		setError("");
		setSuccess(""); // Clear any previous success message
		try {
			const docRef = doc(db, "beta", email);
			await setDoc(docRef, {
				createdAt: serverTimestamp(),
			});
			setEmail("");
			setSuccess("Successfully signed up for the beta!"); // Set success message
		} catch (err) {
			setError(err.message);
		}
	};

	return (
		<Container fluid style={{ minHeight: "80vh" }}>
			<Row className="d-flex align-items-center justify-content-center">
				<Col xs={12} md={6} lg={6} className="mx-auto">
					<div className="p-4 box text-center">
						<h2 className="mb-3 display-4 fw-bold">
							Sign up for the beta
						</h2>
					</div>
				</Col>
			</Row>
			<Row className="d-flex align-items-center justify-content-center">
				<Col xs={12} md={4} lg={4} className="mx-auto">
					<div className="p-4 box text-center">
						<p>
							Get notified when we launch our beta and be the
							first to access our new features!
						</p>
						<h4 className="fw-bold mb-1">Features include</h4>
						<ListGroup bsPrefix="list-unstyled mb-4">
							<ListGroup.Item
								className="mb-1"
								bsPrefix="list-item"
							>
								<span className="text-success me-2">
									<i className="far fa-check-circle"></i>
								</span>
								<span>Real-time filing notifications</span>
								<span className="text-muted ms-2">🔔</span>
							</ListGroup.Item>
							<ListGroup.Item
								className="mb-1"
								bsPrefix="list-item"
							>
								<span className="text-success me-2">
									<i className="far fa-check-circle"></i>
								</span>
								<span>Custom filing alerts</span>
								<span className="text-muted ms-2">🚨</span>
							</ListGroup.Item>
							<ListGroup.Item
								className="mb-1"
								bsPrefix="list-item"
							>
								<span className="text-success me-2">
									<i className="far fa-check-circle"></i>
								</span>
								<span>Company document summaries</span>
								<span className="text-muted ms-2">📄</span>
							</ListGroup.Item>
							<ListGroup.Item
								className="mb-1"
								bsPrefix="list-item"
							>
								<span className="text-success me-2">
									<i className="far fa-check-circle"></i>
								</span>
								<span>Company document search</span>
								<span className="text-muted ms-2">🔍</span>
							</ListGroup.Item>

							<ListGroup.Item
								className="mb-1"
								bsPrefix="list-item"
							>
								<span className="text-success me-2">
									<i className="far fa-check-circle"></i>
								</span>
								<span>
									S-1, 4, 13D, 13G, 8-K, 10-Q, 10-K, D, 144,
									14A forms
								</span>
								<span className="text-muted ms-2">📑</span>
							</ListGroup.Item>
						</ListGroup>
						{error && <Alert variant="danger">{error}</Alert>}
						{success && (
							<Alert variant="success">{success}</Alert>
						)}{" "}
						{/* Display success message */}
						<Form onSubmit={handleSubmit}>
							<Form.Group
								className="mb-3"
								controlId="formBasicEmail"
							>
								<Form.Control
									type="email"
									placeholder="Email address"
									onChange={(e) => setEmail(e.target.value)}
									className="text-center"
								/>
							</Form.Group>

							<div className="d-grid gap-2">
								<Button variant="primary" type="Submit">
									Sign up
								</Button>
							</div>
						</Form>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default BetaSignUp;
