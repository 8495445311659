import { createContext, useContext, useEffect, useState, useRef } from "react";
import {
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	signOut,
	GoogleAuthProvider,
	signInWithPopup,
	updateProfile,
	updatePassword,
} from "firebase/auth";
import { auth } from "../firebase";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
	const [user, setUser] = useState(null);
	const [userRole, setUserRole] = useState(null);
	const [loading, setLoading] = useState(true);

	function updateDisplayName(name) {
		if (user) {
			return updateProfile(user, { displayName: name });
		} else {
			throw new Error("No user is logged in");
		}
	}

	function changePassword(newPassword) {
		if (user) {
			return updatePassword(user, newPassword);
		} else {
			throw new Error("No user is logged in");
		}
	}

	function logIn(email, password) {
		return signInWithEmailAndPassword(auth, email, password);
	}
	function signUp(email, password) {
		return createUserWithEmailAndPassword(auth, email, password);
	}
	function logOut() {
		return signOut(auth);
	}
	function signInWithGoogle() {
		const googleAuthProvider = new GoogleAuthProvider();
		return signInWithPopup(auth, googleAuthProvider);
	}

	async function getCustomClaimRole(user) {
		if (!user) return null;
		await user.getIdToken(true);
		const decodedToken = await user.getIdTokenResult();
		const { admin, stripeRole } = decodedToken.claims;
		return { admin, subscription: stripeRole };
	}

	const unsubscribe = useRef(null);

	useEffect(() => {
		unsubscribe.current = onAuthStateChanged(auth, async (currentuser) => {
			if (currentuser) {
				const decodedToken = await getCustomClaimRole(user);
				setUserRole(decodedToken);
				setUser(currentuser);
			} else {
				setUserRole(null);
				setUser(null);
			}
			setLoading(false);
		});

		// Return a cleanup function that is called when the component unmounts
		return () => unsubscribe.current && unsubscribe.current();
	}, []);

	return (
		<userAuthContext.Provider
			value={{
				user,
				userRole,
				loading,
				logIn,
				signUp,
				logOut,
				signInWithGoogle,
				updateDisplayName,
				changePassword,
			}}
		>
			{children}
		</userAuthContext.Provider>
	);
}

export function useUserAuth() {
	return useContext(userAuthContext);
}
