// import node module libraries
import {
	Container,
	Row,
	Col,
	Table,
	Badge,
	Button,
	Form,
	Image,
} from "react-bootstrap";

// import MDI icons
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useUserAuth } from "../../context/UserAuthContext";
import logoPlaceholder from "../../assets/images/png/logo-placeholder.png";

const functions = getFunctions();
const removeListener = httpsCallable(functions, "removeListener");
const addListener = httpsCallable(functions, "addListener");

const Company = () => {
	const [selectedCompany, setSelectedCompany] = useState(true);
	const { user } = useUserAuth();
	const { companyId } = useParams();
	//   companyId is a CIK number
	const [company, setCompany] = useState({});
	const [, setCompanyDetails] = useState({});
	const [filings, setFilings] = useState([]);

	const removeLeadingZeros = (str) => {
		str = str.toString();
		return str.replace(/^0+/, "");
	};

	//   make a request to tde backend to get the filings for this company
	useEffect(() => {
		// get the company from firestore
		const getCompany = async () => {
			try {
				const companyRef = doc(db, "companies", companyId);
				const companyDoc = await getDoc(companyRef);
				setCompany(companyDoc.data());
				// if theres no industry field, call the getCompany function from the backend
				if (!companyDoc.data().industry) {
					const getCompany = httpsCallable(functions, "getCompany");
					const result = await getCompany({ companyId: companyId });
					setCompany(result.data);
				}
			} catch (err) {
				console.log(err);
			}
		};

		const getFilings = async () => {
			try {
				const res = await axios.get(
					`https://data.sec.gov/submissions/CIK${companyId}.json`
				);

				setCompanyDetails({
					tickers: res.data.tickers,
					exchanges: res.data.exchanges,
					description: res.data.sicDescription,
				});
				// res.data.filings.recent has several keys with array values: accessionNumber, form, filingDate, primaryDocument
				// the values in each array are in order and correspond to the same filing
				// create an object for each accessionNumber and add it to the filings array

				const recentFilings = [];

				for (
					let i = 0;
					i < res.data.filings.recent.accessionNumber.length;
					i++
				) {
					if (
						["10-K", "10-Q", "8-K"].includes(
							res.data.filings.recent.form[i]
						)
					) {
						recentFilings.push({
							accessionNumber:
								res.data.filings.recent.accessionNumber[i],
							form: res.data.filings.recent.form[i],
							filingDate: res.data.filings.recent.filingDate[i],
							primaryDocument:
								res.data.filings.recent.primaryDocument[i],
						});
					}
				}

				setFilings(recentFilings);
			} catch (err) {
				console.log(err);
			}
		};

		const getUserDoc = async () => {
			if (!user) return;
			const userDocRef = doc(db, "users", user.uid);
			const userDocSnap = await getDoc(userDocRef);
			const userDoc = userDocSnap.data();

			// if the user has a listeners array, set the selectedCompany state to true if the listeners array includes the companyId
			if (userDoc.listeners && userDoc.listeners.includes(companyId)) {
				setSelectedCompany(true);
			}
		};

		getUserDoc();
		getCompany();
		getFilings();
	}, [companyId, user]);

	const [filters, setFilters] = useState({
		"10-K": true,
		"10-Q": true,
		"8-K": true,
	});

	const handleFilterChange = (formType) => {
		setFilters({
			...filters,
			[formType]: !filters[formType],
		});
	};

	const handleButtonClick = async (cik) => {
		if (selectedCompany) {
			setSelectedCompany(false);
			// Call the removeListener function
			try {
				const result = await removeListener({ cik });
			} catch (error) {
				console.error(error); // handle the error if needed
			}
		} else {
			setSelectedCompany(true);
			// Call the addListener function
			try {
				const result = await addListener({ cik });
				console.log(result.data); // handle the result if needed
			} catch (error) {
				console.error(error); // handle the error if needed
			}
		}
	};

	return (
		<Container>
			<Row className="mt-6 mb-3">
				<Col className="d-flex justify-content-between align-items-start">
					<div>
						<h1 className="d-flex align-items-center">
							<Image
								src={company.logo || logoPlaceholder}
								alt={company.name}
								style={{ width: "5rem", height: "5rem" }}
								className="me-3"
								onError={(e) => {
									e.target.onerror = null;
									e.target.src = logoPlaceholder;
								}}
							/>{" "}
							{company.name}
						</h1>
						{/* no borders */}
						<div>
							<div className="mb-3">
								<strong>Symbol:</strong> {company.symbol}
							</div>
							<div className="mb-3">
								<strong>Industry:</strong> {company.industry}
							</div>
							<div className="mb-3">
								<strong>Exchange:</strong> {company.exchange}
							</div>

							<div className="mb-3">
								<strong>Website:</strong>
								<a
									href={company.website}
									target="_blank"
									rel="noreferrer"
								>
									{company.website}
								</a>
							</div>
							<div className="mb-3">
								<strong>Business Summary:</strong>{" "}
								{company.longBusinessSummary}
							</div>
						</div>
					</div>
					{/* heart button to save company to user's favorites */}
					{/* <Button
						className={
							selectedCompany
								? "btn btn-success btn-sm"
								: "btn btn-light btn-sm"
						}
						onClick={(event) => {
							event.preventDefault();
							handleButtonClick(company.cik);
						}}
					>
						{selectedCompany ? (
							<i className="fe fe-check"></i>
						) : (
							<i className="fe fe-plus"></i>
						)}
					</Button> */}
				</Col>
			</Row>
			<Row>
				<Col>
					<h3 className="mb-3">Filings</h3>
					<Form>
						<Form.Group>
							<Form.Check
								inline
								type="checkbox"
								label="10-K"
								checked={filters["10-K"]}
								onChange={() => handleFilterChange("10-K")}
							/>
							<Form.Check
								inline
								type="checkbox"
								label="10-Q"
								checked={filters["10-Q"]}
								onChange={() => handleFilterChange("10-Q")}
							/>
							<Form.Check
								inline
								type="checkbox"
								label="8-K"
								checked={filters["8-K"]}
								onChange={() => handleFilterChange("8-K")}
							/>
						</Form.Group>
					</Form>
					<Table responsive>
						<thead>
							<tr>
								<th>Form</th>
								<th>Filing Date</th>
								<th>Official Document</th>
							</tr>
						</thead>
						<tbody>
							{filings
								.filter((filing) => filters[filing.form])
								.map((filing) => (
									<tr key={filing.accessionNumber}>
										<td>
											<Badge
												bg={
													filing.form === "10-K"
														? "warning"
														: filing.form === "10-Q"
															? "info"
															: "danger"
												}
												className="rounded-1"
											>
												{filing.form}
											</Badge>
										</td>
										<td>{filing.filingDate}</td>
										<td>
											{company.cik && (
												<a
													target="_blank"
													href={`https://www.sec.gov/Archives/edgar/data/${removeLeadingZeros(
														company.cik
													)}/${filing.accessionNumber.replaceAll("-", "")}/${
														filing.primaryDocument
													}`}
												>
													View document
												</a>
											)}
										</td>
									</tr>
								))}
						</tbody>
					</Table>
				</Col>
			</Row>
		</Container>
	);
};

export default Company;
